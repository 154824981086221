// import logo from "../src/logo.jpg";
import japanMont from "../src/japan_mont.png";
import "./App.css";

function App() {
  return (
    <div className="app__container">
      <div className="logo__container">
        <img src={japanMont} alt="GARAŽNA VRATA"></img>
      </div>

      <div className="text__container">
        <div className="page__title">
          <h1>Ugradnja I Prodaja Garažnih Vrata</h1>
          <h2>Uskoro Sa Vama Na Ovoj Adresi</h2>
        </div>
        {/* <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200&display=swap');
        </style> */}
      </div>

      <div className="contact__info">
        <a href="tel: +381606667021">Telefon: 060 666 7021</a>
        <a href="tel: +381631836920">Telefon: 063 1836 920</a>
        <a href="mailto: srb@jmdrmont.com">Email: srb@jmdrmont.com</a>
      </div>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@200&display=swap');
      </style>
    </div>
  );
}

export default App;
